/**
 * Foursource page
 * https://foursource.com/garment-manufacturers-from-peru
 */

import * as React from "react";
import { withTrans } from "../i18n/withTrans";

import { pageTexts } from "../content/garment-manufacturers-from-peru";

// components
import Layout from "../components/layout";
import { StaticImage } from "gatsby-plugin-image";
import BulletsSection from "../components/bulletsSection";
import TextImgBackgroundFull from "../components/textImgBackgroundFull";
import ButtonLink from "../components/_partials/_buttonLink";
import ImgLabel from "../components/imgLabel";

class GarmentManufacturersFromPeruPage extends React.Component {
  constructor(props) {
    super(props);
    this.t = props.t;
    this.text = pageTexts(this.t);
  }

  render() {
    return (
      <>
        <Layout pageName="garment-manufacturers-from-peru" whiteMenu={false}>
          <TextImgBackgroundFull info={this.text.topInfo}></TextImgBackgroundFull>
          <div className="d-flex justify-content-center pt-50 pb-50 paddingSidesBig flex-column">
            <div className="d-flex justify-content-center registerBanner flex-wrap">
              <a href="https://www.perumoda.com/?utm_source=foursource&utm_medium=banner&utm_campaign=perumoda2020">
                <StaticImage width={500} src="../images/bannerPeruLeft.jpg" alt="registry image"></StaticImage>
              </a>
              <div className="position-relative">
                <div>
                  <StaticImage width={500} src="../images/bannerRegisterHere.png" alt="registerHere Banner"></StaticImage>
                </div>
                <button className="ctaButton purpleButton">{this.text.register}</button>
              </div>
            </div>

            <div className="introductionText pt-50 pb-50">
              <div className="font26 fontWeight400">
                {this.text.introText}
              </div>
            </div>

            <div className="peruBullets">
              <div className="font60 fontWeightBold pb-50">{this.text.bulletsTitle}</div>
              <div>
                <BulletsSection info={this.text.bullets}></BulletsSection>
              </div>
            </div>

            <div className="imgLabelPeru">
              <div className="labelTitle font34 fontWeightBold">{this.text.labelTitle}</div>

              <div className="pt-50 pt-sm-10 row row-cols-2 row-cols-xl-4 row-cols-lg-4 row-cols-md-4 row-cols-sm-3">
                {this.text.contents.map((content, a) => (
                  <div key={a} className="p-4 boxShadow">
                    <ImgLabel info={content}></ImgLabel>
                  </div>
                ))}
              </div>
              <div className="d-flex justify-content-center pb-20 pt-50">
                <ButtonLink text={this.text.buttonText} url={this.text.buttonUrl} type={this.text.buttonType}></ButtonLink>
              </div>
            </div>
          </div>
          <div className="descriptionsPeru backgroundGrey pt-50">
            <div className="paddingSidesBig">
              <div className="d-flex justify-content-center pb-50">
                <StaticImage src="../images/promperuLogo.png" alt="prom peru logo"></StaticImage>
              </div>
              <div className="promPeruDescription pb-50 font24">
                {this.text.promDescription}
              </div>
              <div className="sustainability d-flex align-items-center pb-50 flex-column">
                <div className="font40 fontWeightBold pb-50">{this.text.sustainabilityTitle}</div>
                <div className="font20">
                  {this.text.sustainabilityText}
                </div>
              </div>
              <div className="d-flex justify-content-center pb-50">
                <div className="font34 fontWeightBold">{this.text.nationalBrands}</div>
              </div>
              <div className="d-flex justify-content-center pb-50">
                <a href="http://alpacadelperu.com.pe/EN/?utm_source=foursource&utm_medium=logo&utm_campaign=perumoda2020">
                  <StaticImage src="../images/alpacaPeruLogo.png" alt="alpaca Peru Logo"></StaticImage>
                </a>
                <a href="https://peru.info/en-us/peru-textiles?utm_source=foursource&utm_medium=logo&utm_campaign=perumoda2020">
                  <StaticImage src="../images/peruTextilesLogo.png" alt="Peru Textiles Logo"></StaticImage>
                </a>
              </div>
            </div>
          </div>
        </Layout>
      </>
    );
  }
}

export default withTrans(GarmentManufacturersFromPeruPage);
