import * as React from "react";

class imgLabel extends React.Component {
  render() {
    const { info } = this.props;

    return (
      <>
        <div className="imgLabelContent backgroundWhite boxRadius p-15 h-100 ">
          {info.href ? (
            <a href={info.href} target="_blank" rel="noreferrer">
              <div className="img-content">
                <img className="h-100 w-100" alt={info.img.alt} src={info.img.src} />
              </div>
            </a>
          ) : (
            <div className="img-content">
              <img className="h-100 w-100" alt={info.img.alt} src={info.img.src} />
            </div>
          )}

          <p className="text-center pt-10 pb-10 m-0 font17">{info.label}</p>
          <p className="text-center pt-10 pb-10 m-0 font14 colorDarkLight">{info?.description}</p>
        </div>
      </>
    );
  }
}

export default imgLabel;
