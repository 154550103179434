// components
import Utm from "../components/_global/utm";

// images
import CheckIcon from "../images/icon/check.svg";
import TopBackground from "../images/peruHeader.jpg";
import artLogo from "../images/garmentManufacturersLogosPeru/art-atlas.jpeg";
import keroDesign from "../images/garmentManufacturersLogosPeru/kero-design.png";
import corceli from "../images/garmentManufacturersLogosPeru/corceli.jpg";
import tandem from "../images/garmentManufacturersLogosPeru/tandem-textil.png";
import lives from "../images/garmentManufacturersLogosPeru/lives-sac.png";
import incalpaca from "../images/garmentManufacturersLogosPeru/incalpaca-textiles-pereuanos.png";
import industria from "../images/garmentManufacturersLogosPeru/industria-textil-del-pacifico.jpg";
import intratesa from "../images/garmentManufacturersLogosPeru/intratesa.jpeg";
import ifer from "../images/garmentManufacturersLogosPeru/ifer-knitwear.png";
import lucia from "../images/garmentManufacturersLogosPeru/grupo-santa-lucia.png";
import franky from "../images/garmentManufacturersLogosPeru/franky-y-ricky.png";
import topy from "../images/garmentManufacturersLogosPeru/topy-top.jpg";
import valle from "../images/garmentManufacturersLogosPeru/textil-del-valle1.jpg";
import southern from "../images/garmentManufacturersLogosPeru/southern-textile-network.png";
import cotton from "../images/garmentManufacturersLogosPeru/cotton-project.jpg";
import textimax from "../images/garmentManufacturersLogosPeru/textimas-logo.jpg";
import peru from "../images/garmentManufacturersLogosPeru/peru-fashions-sac.jpg";
import nettalco from "../images/garmentManufacturersLogosPeru/industrias-nettalco.png";
import sac from "../images/garmentManufacturersLogosPeru/catalogo-sac.png";
import preco from "../images/garmentManufacturersLogosPeru/precotex.jpg";

//images associates

export const pageTexts = (t) => {
  return {
    topInfo: {
      title: {
        text: t("garment-manufacturers-from-peru.topTitle"),
        colorClass: "colorLight",
        extraClass: "font150 fontWeight800",
      },
      opacity: true,
      image: {
        smallHeight: true,
        src: TopBackground,
        alt: t("garment-manufacturers-from-peru.imgAlt"),
      },
    },
    bullets :{
      icon: { fontAwesome: false, src: CheckIcon, alt: t("homepage.checkAlt")},
      separator: false,
      item: {
        text: [
          t("garment-manufacturers-from-peru.freeTrade"),
          t("garment-manufacturers-from-peru.logisticsHub"),
          t("garment-manufacturers-from-peru.textileCluster"),
          t("garment-manufacturers-from-peru.globalBrands"),
        ],
        colorClass: "colorDark",
      },
    
  },
  buttonText:t("garment-manufacturers-from-peru.buttonText"),
  buttonUrl: process.env.GATSBY_CHINA === "true" ? `${process.env.APP_CHINA_URL}/signup/` : Utm(`${process.env.APP_URL}/signup/`),
  buttonType:"mainColor bigWidth",
  contents: [
    {
      img: { src: artLogo, alt: "artLogo" },
      label: t("garment-manufacturers-from-peru.artAtlas"),
      description: t("garment-manufacturers-from-peru.artAtlasDescription"),
      href: "https://pro.foursource.com/artatlasperu",
    },
    {
      img: { src: keroDesign, alt: "keroDesignLogo" },
      label: t("garment-manufacturers-from-peru.keroDesign"),
      description: t("garment-manufacturers-from-peru.keroDesignDescription"),
      href: "https://pro.foursource.com/kerodesign",
    },
    {
      img: { src: corceli, alt: "corceliLogo" },
      label: t("garment-manufacturers-from-peru.corceli"),
      description: t("garment-manufacturers-from-peru.corceliDescription"),
      href: "https://pro.foursource.com/corcelisac",
    },
    {
      img: { src: tandem, alt: "tandemLogo" },
      label: t("garment-manufacturers-from-peru.tandem"),
      description: t("garment-manufacturers-from-peru.tandemDescription"),
      href: "https://pro.foursource.com/tandem-textil",
    },
    {
      img: { src: lives, alt: "livesLogo" },
      label: t("garment-manufacturers-from-peru.lives"),
      description: t("garment-manufacturers-from-peru.livesDescription"),
      href: "https://pro.foursource.com/LIVES",
    },
    {
      img: { src: incalpaca, alt: "incalpacaLogo" },
      label: t("garment-manufacturers-from-peru.incalpaca"),
      description: t("garment-manufacturers-from-peru.incalpacaDescription"),
      href: "https://pro.foursource.com/Incalpaca2020",
    },
    {
      img: { src: industria, alt: "industriaLogo" },
      label: t("garment-manufacturers-from-peru.industria"),
      description: t("garment-manufacturers-from-peru.industriaDescription"),
      href: "https://pro.foursource.com/industria-textil-del-pacifico",
    },
    {
      img: { src: intratesa, alt: "intratesaLogo" },
      label: t("garment-manufacturers-from-peru.intratesa"),
      description: t("garment-manufacturers-from-peru.intratesaDescription"),
      href: "https://pro.foursource.com/intratesa",
    },
    {
      img: { src: ifer, alt: "iferLogo" },
      label: t("garment-manufacturers-from-peru.ifer"),
      description: t("garment-manufacturers-from-peru.iferDescription"),
      href: "https://pro.foursource.com/ifer-knitwear-sac",
    },
    {
      img: { src: lucia, alt: "luciaLogo" },
      label: t("garment-manufacturers-from-peru.lucia"),
      description: t("garment-manufacturers-from-peru.luciaDescription"),
      href: "https://pro.foursource.com/cocotfyma",
    },
    {
      img: { src: franky, alt: "frankyLogo" },
      label: t("garment-manufacturers-from-peru.frankyName"),
      description: t("garment-manufacturers-from-peru.frankyDescription"),
      href: "https://pro.foursource.com/frankyandricky",
    },
    {
      img: { src: topy, alt: "topyLogo" },
      label: t("garment-manufacturers-from-peru.topy"),
      description: t("garment-manufacturers-from-peru.topyDescription"),
      href: "https://pro.foursource.com/topitopsa",
    },
    {
      img: { src: valle, alt: "valleLogo" },
      label: t("garment-manufacturers-from-peru.valle"),
      description: t("garment-manufacturers-from-peru.valleDescription"),
      href: "https://pro.foursource.com/textil-del-valle-2",
    },
    {
      img: { src: southern, alt: "southernLogo" },
      label: t("garment-manufacturers-from-peru.southern"),
      description: t("garment-manufacturers-from-peru.southernDescription"),
      href: "https://pro.foursource.com/stn",
    },
    {
      img: { src: cotton, alt: "cottonLogo" },
      label: t("garment-manufacturers-from-peru.cotton"),
      description: t("garment-manufacturers-from-peru.cottonDescription"),
      href: "https://pro.foursource.com/cotton-project-sac",
    },
    {
      img: { src: textimax, alt: "textimaxLogo" },
      label: t("garment-manufacturers-from-peru.textimax"),
      description: t("garment-manufacturers-from-peru.textimaxDescription"),
      href: "https://pro.foursource.com/TEXTIMAX",
    },
    {
      img: { src: peru, alt: "peruLogo" },
      label: t("garment-manufacturers-from-peru.peru"),
      description: t("garment-manufacturers-from-peru.peruDescription"),
      href: "https://pro.foursource.com/peru-fashions-sac",
    },
    {
      img: { src: nettalco, alt: "nettalcoLogo" },
      label: t("garment-manufacturers-from-peru.nettalco"),
      description: t("garment-manufacturers-from-peru.nettalcoDescription"),
      href: "https://pro.foursource.com/nettalco",
    },
    {
      img: { src: sac, alt: "sacLogo" },
      label: t("garment-manufacturers-from-peru.sac"),
      description: t("garment-manufacturers-from-peru.sacDescription"),
      href: "https://pro.foursource.com/catalogo-sac",
    },
    {
      img: { src: preco, alt: "precoLogo" },
      label: t("garment-manufacturers-from-peru.preco"),
      description: t("garment-manufacturers-from-peru.precoDescription"),
      href: "https://pro.foursource.com/precotex",
    },
  ],
  register: t("garment-manufacturers-from-peru.register"),
  introText: t("garment-manufacturers-from-peru.introText"),
  bulletsTitle: t("garment-manufacturers-from-peru.bulletsTitle"),
  labelTitle: t("garment-manufacturers-from-peru.labelTitle"),
  promDescription: t("garment-manufacturers-from-peru.promDescription"),
  sustainabilityTitle: t("garment-manufacturers-from-peru.sustainabilityTitle"),
  sustainabilityText:t("garment-manufacturers-from-peru.sustainabilityText"),
  nationalBrands:t("garment-manufacturers-from-peru.nationalBrands")
  };
};
